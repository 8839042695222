import React from 'react';

import CardFrame from '../common/CardFrame';
import EventInfoRow from './EventInfoRow';

import {Box, Button, Grid} from '@mui/material';
import dayjs from 'dayjs';
import {useRecordContext} from 'components/record/useRecordContext';
import MapContainer from 'components/common/MapContainer';
import DraggableMarkers from 'components/common/DraggableMarker';
import icons from '../Control/Icons/MarkerIcons';
import {Info} from '@mui/icons-material';

const EventInfo = () => {
  const {record} = useRecordContext();

  return (
    <Grid container>
      <Grid item xs={12} md={6} padding={2}>
        <CardFrame title="Event Info" avatar={<Info />}>
          <EventInfoRow title="Name" source={record.name} />
          <EventInfoRow title="Abbreviation" source={record.slug} />
          <EventInfoRow title="Category" source={record.category} />
          <EventInfoRow title="Start Date" source={dayjs(record.startDate).format('YYYY-MM-DD HH:mm')} />
          <EventInfoRow title="End Date" source={dayjs(record.endDate).format('YYYY-MM-DD HH:mm')} />
          <EventInfoRow title="Published" source={dayjs(record.publicDate).format('YYYY-MM-DD HH:mm')} />
          <EventInfoRow title="Hidden" source={record.hidden ? 'true' : ''} />
          <EventInfoRow title="Is Open" source={record.isOpen ? 'true' : ''} />
          <EventInfoRow title="Description" source={record.description} />
          <EventInfoRow title="Web Page" source={record.web} />
          <EventInfoRow title="Link" source={<Button
            component="a"
            className="event-name"
            target="_blank"
            rel="noopener noreferrer"
            href={process.env.REACT_APP_DOMAIN_EVENTS + record.slug}
          >
            {record.name}
          </Button>} />
        </CardFrame>
      </Grid>
      <Grid item xs={12} md={6} padding={2}>
        <Box sx={{marginBottom: 2}}>
          <MapContainer
            center={record.location}
            zoom={record.zoom}
            className="leaflet-crosshair"
            style={{width: '100%', height: '400px', position: 'relative'}}
          >
            <DraggableMarkers position={record.location} icon={icons['center']} />
          </MapContainer>
        </Box>
      </Grid>
    </Grid>
  );
};
export default EventInfo;
