import React from 'react';
import {Paper, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';

interface MapLabels {
  [key: string]: string | undefined;
}

const DataTable = (props: {
  args: Array<Record<string, string | number | JSX.Element>>;
  keys: Array<string>;
  labels: MapLabels;
  idField?: Array<string>;
  children?: React.ReactNode;
  sx?: SxProps;
  className?: string;
}): JSX.Element => {
  const {args, labels, keys, idField = ['id'], ...rest} = props;

  return (
    <React.Fragment>
      {args && args.length > 0 && (
        <TableContainer component={Paper} {...rest}>
          <Table size="small" stickyHeader style={{tableLayout: 'fixed'}}>
            <TableHead>
              <TableRow>
                {keys.map((key: string) => (
                  <TableCell
                    key={key}
                    style={{
                      fontWeight: 600,
                      fontSize: '15px',
                      color: 'rgba(0, 0, 0, 0.54)'
                    }}
                  >
                    {labels[key]}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {args.map((value: Record<string, string | number | JSX.Element>) => {
                return (
                  <TableRow key={idField.map((id) => value[id]).join(' ')}>
                    {keys.map((key) => {
                      if (Object.keys(value).includes(key)) {
                        return <TableCell key={key}>{value[key]}</TableCell>;
                      }
                    })}
                  </TableRow>
                );
              })}
              {props.children}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </React.Fragment>
  );
};

export default DataTable;
