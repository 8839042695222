import React from 'react'

import {Error} from '@mui/icons-material';
import List from 'components/list/List';
import {DateField, LinkField, ListField} from 'components/list/Fields';

const LogsPage = () => {
  return (
    <React.Fragment>
      <List
        avatar={<Error />}
        title="Devices"
        bulkOptions={false}
        hidePagination
      >
        <LinkField source="name" label="ID" link={(record) => `/logs/${record?.id}`} />
        <ListField source="name" label="Name" />
        <DateField source="time" label="Time" />
      </List>
    </React.Fragment>
  )
}

export default LogsPage
