import React from 'react'
import { useRecordContext } from 'components/record/useRecordContext';
import DataTable from 'components/common/DataTable';
import CardFrame from 'components/common/CardFrame';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

const keys = ['level', 'timestamp', 'message', 'meta'];
const labels = {
  'level': 'Level',
  'timestamp': 'Time',
  'message': 'Message',
  'meta': 'Data'
};

const LogShow = () => {
  const {record: log} = useRecordContext();

  return (
    <CardFrame title={log.name} action={
      <Button component={Link} to={`/logs`}>
        Back
      </Button>
    }>
      <DataTable 
        args={
          log.data?.map((data: {meta: string}, index: number) => {
            const {meta, ...rest} = data;
            const metaInfo: {[key: string]: string} = JSON.parse(meta);
            return {
              meta: (
                <React.Fragment>
                  {Object.keys(metaInfo).map((key: string) => (
                    <p key={key}>
                      {key}: {JSON.stringify(metaInfo[key])}
                    </p>
                  ))}
                </React.Fragment>
              ),
              index,
              ...rest
            };
          }) || []
        }
        keys={keys}
        labels={labels}
        idField={['index']}
        />
      </CardFrame>
  )
}

export default LogShow
