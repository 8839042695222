import React, { ReactElement } from 'react';
import {Grid} from '@mui/material';

type EventInfoRowProps = {
  title: string;
  source: string | ReactElement;
};

const EventInfoRow = (props: EventInfoRowProps) => {
  const {title, source} = props;

  return (
    <React.Fragment>
      <Grid item xs={12} padding={2} container>
        <Grid item xs={4}>
          {title}
        </Grid>
        <Grid item xs={8}>
          {source}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default EventInfoRow;
