import React from 'react';

import {DirectionsRun, EditLocationAlt, FileDownload} from '@mui/icons-material';
import {useRecordContext} from 'components/record/useRecordContext';
import {ResourceContextProvider} from 'components/record/ResourceContext';
import {ColorField, DateField, ListField} from 'components/list/Fields';
import List from 'components/list/List';
import {CircularProgress} from '@mui/material';
import CreateButton from 'components/forms/CreateButton';
import CompetitorForm from './CompetitorForm';
import {useFetch} from 'hooks/useFetchData';
import {DeviceType} from 'components/Devices/DevicesPage';
import ActionMenu from 'components/list/actionMenu/ActionMenu';
import ActionMenuItemEdit from 'components/list/actionMenu/ActionMenuItemEdit';
import {ActionMenuItemOrderDown, ActionMenuItemOrderUp} from 'components/list/actionMenu/ActionMenuItemsOrder';
import ActionMenuItemDelete from 'components/list/actionMenu/ActionMenuItemDelete';
import CompetitorUserGroupForm from './CompetitorUserGroupForm';
import ActionMenuItemDialog from 'components/list/actionMenu/ActionMenuItemDialog';
import SingleTrack from 'components/MyEvents/Track/SingleTrack';
import ExportTrack from 'components/MyEvents/Track/ExportTrack';

const Competitors = () => {
  const {record: subEvent} = useRecordContext();

  const {data: deviceData, loading} = useFetch<{items: DeviceType[]}>('GET_LIST', 'Device');

  const deviceList: {id: number; label: string}[] = React.useMemo(() => {
    return deviceData?.items.map((device) => ({id: device.imei, label: device.name})) || [];
  }, [deviceData]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <ResourceContextProvider resource="Competitor" params={{subEventId: subEvent.id}}>
      <List
        bulkOptions
        hidePagination
        title="Competitors"
        avatar={<DirectionsRun />}
        toolbar={
          <React.Fragment>
            <CreateButton label="Add from Group" dialogTitle="Add from User Group" many>
              <CompetitorUserGroupForm />
            </CreateButton>
            <CreateButton label="Add Competitor">
              <CompetitorForm subEvent={subEvent} devices={deviceList} />
            </CreateButton>
          </React.Fragment>
        }
      >
        <ListField source="name" label="Name" />
        <ListField source="device.name" label="Device" />
        <ColorField source="color" label="Color" />
        <DateField source="startTime" label="Start Time" format="HH:mm:ss" />
        {subEvent.event.settings.useSI && <ListField source="siCard" label="SI Card" />}
        <ActionMenu label="Actions" align="right" width="5%">
          <ActionMenuItemDialog label="Track" icon={<EditLocationAlt />} confirmTitle="Track" confirmContent={<SingleTrack subEvent={subEvent} />} />
          <ActionMenuItemDialog
            label="Export"
            icon={<FileDownload />}
            confirmTitle="Export Track"
            confirmContent={<ExportTrack subEvent={subEvent} />}
          />
          <ActionMenuItemEdit confirmContent={<CompetitorForm subEvent={subEvent} devices={deviceList} />} />
          <ActionMenuItemOrderUp />
          <ActionMenuItemOrderDown />
          <ActionMenuItemDelete />
        </ActionMenu>
      </List>
    </ResourceContextProvider>
  );
};

export default Competitors;
